import React from 'react'
import { DispatchItemInterface } from '../../../../interfaces/ServerInterfaces';
import DispatchWizard from './modalNewDispatch/DispatchWizard';

interface TransformToDispatchInterface {
    id: number,
    despacho: DispatchItemInterface | null,
    response: Function,
    setTransformToDispatch: React.Dispatch<React.SetStateAction<boolean>>
    loading: boolean;
}

export const TransformToDispatch = ({
    id,
    despacho,
    response,
    setTransformToDispatch,
    loading
}: TransformToDispatchInterface) => {

    const onSubmitTransformToDispacth = () => {
        const payload = {
            "mode": despacho?.mode,
            "observations": despacho?.observations,
            "products": despacho?.products.map(prod => ({
                productId: prod.productId,
                quantity: prod.quantity
            })),
            "stockAreaFromId": despacho?.stockAreaFrom?.id,
            "stockAreaToId": despacho?.stockAreaTo?.id
        };

        response!(id!, "DISPATCHED", despacho?.isPreDispatch!, payload, () => setTransformToDispatch(false));
    }

    return (
        <div className="flex flex-col  gap-3 w-full">
            <h2 className="text-xl font-semibold mb-6">
                Transformar predespacho a despacho
            </h2>

            <DispatchWizard
                submitAction={onSubmitTransformToDispacth}
                loading={false}
                transformingToDispatch
                despacho={despacho!}
            />

            {/* <div className="self-end">
                <Button
                    color="slate-600"
                    textColor="white"
                    type="submit"
                    name="Aceptar"
                    action={onSubmitTransformToDispacth}
                />
            </div> */}
        </div>
    )
}
